import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import ghp from '../images/team/ghp.jpg';
import marco from '../images/team/valenzuela.jpg';
// import { ReactComponent as Logo } from '../images/logos/lum-grey.svg';
import Sidebar from '../components/common/Sidebar';
import Scroll from '../components/common/Scroll';

const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div id="wrapper">

      <section id="intro" className="wrapper style1 fullscreen fade-up">
        <div className="inner">
          <h1>Lum AI</h1>
          <h3>Accelerating your workflows</h3>
          <p>
          Lum specializes in novel natural language processing and machine reading technology providing bespoke solutions for commercial and government customers. 
  
          Let us help you increase opportunities by automating your tedious and repetitive tasks.
          </p>
          <ul className="actions">
            <li>
              <Scroll type="id" element="who-we-are">
                <a href="#who-we-are" className="button">
                  Learn more
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </section>

      <section id="who-we-are" className="wrapper style2 spotlights">
      {/* We are a team of researchers with experience and expertise in developing language understanding systems for sources as diverse as social media and scientific publications. These systems incorporate both state-of-the-art approaches in artificial intelligence, as well as rule-based components that encode detailed domain knowledge. Our hybridized approach produces practical systems that are both powerful and versatile. */}
        <section>
          <div className="content">
            <div className="inner">
              <h2>Kevin McLaughlin</h2>
              <h3>Chief Executive Officer & Co-founder</h3>
              <p>
              Kevin advised the team at the University of Arizona prior to co-founding Lum in 2017. He brings over 30 years of experience in technology commercialization, and executive leadership working for companies like Motorola, Cray Research, SGI, Cisco Systems and Avid technology. Kevin works with Tech Launch Arizona as Mentor in Residence for UA startups.
              </p>
            </div>
            <ul className="icons">
                <li>
                  <a href="https://www.linkedin.com/in/kevin-mclaughlin-a4a81" className="fa-linkedin">
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
          </div>
        </section>

        <section>
          <div className="content">
            <div className="inner">
              <h2>Mihai Surdeanu</h2>
              <h3>Chief Scientist & Co-founder</h3>
              <p>
                Associate professor in the Computer Science department at University of Arizona, co-authored over 90 peer-reviewed publications and has over 15 years of experience in building systems driven by NLP and machine learning.
                His experience spans both academia (Stanford University, University of Arizona) and industry (Yahoo! Research and two other NLP-centric startups).
              </p>
            </div>
            <ul className="icons">
              <li>
                <a href="http://surdeanu.cs.arizona.edu/mihai/" className="fa-home">
                  <span className="label">Home</span>
                </a>
              </li>
              <li>
                <a href="https://scholar.google.com/citations?user=a3133-8AAAAJ" className="fa-book">
                <span className="label">Google Scholar</span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/msurd" className="fa-twitter">
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/mihai-surdeanu-a15766146" className="fa-linkedin">
                  <span className="label">LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </section>
        
        <section>
          {/* <a href="/#" className="image" alt="image">
            <img src={ghp} alt="Gus Hahn-Powell" data-position="center center" />
          </a> */}
          <div className="content">
            <div className="inner">
              <h2>Gus Hahn-Powell</h2>
              <h3>Co-founder</h3>
              <p>
              Holds a PhD in Computational Linguistics with a focus on implementing linguistic theory in machine reading systems, ordering and assembling machine-read fragments, and identifying non-interacting research communities engaged in complementary work.
              </p>
            </div>
            <ul className="icons">
              <li>
                <a href="https://parsertongue.org/resume" className="fa-home">
                  <span className="label">Home</span>
                </a>
              </li>
              <li>
                <a href="https://scholar.google.com/citations?user=AV6nozIAAAAJ" className="fa-book">
                <span className="label">Google Scholar</span>
                </a>
              </li>
              <li>
                <a href="https://github.com/myedibleenso" className="fa-github">
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/myedibleenso" className="fa-twitter">
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/gushahnpowell" className="fa-linkedin">
                  <span className="label">LinkedIn</span>
                </a>
                {/* <Link className="button" to="/generic">
                  Learn more
                </Link> */}
              </li>
            </ul>
          </div>
        </section>

        <section>
          {/* <a href="/#" className="image">
            <img src={marco} alt="" data-position="top center" />
          </a> */}
          <div className="content">
            <div className="inner">
              <h2>Marco Valenzuela-Escárcega</h2>
              <h3>Co-founder</h3>
              <p>
                Holds a PhD in Computer Science. He has previous experience with multiple startups and is an expert on machine reading technology.
              </p>
            </div>
            <ul className="icons">
              <li>
                <a href="https://scholar.google.com/citations?user=uU2UhGIAAAAJ" className="fa-book">
                <span className="label">Google Scholar</span>
                </a>
              </li>
              <li>
                <a href="https://github.com/marcovzla" className="fa-github">
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/marco-antonio-valenzuela-esc%C3%A1rcega-08b7a96b" className="fa-linkedin">
                  <span className="label">LinkedIn</span>
                </a>
                {/* <Link className="button" to="/generic">
                  Learn more
                </Link> */}
              </li>
            </ul>
          </div>
        </section>

        <section>
          {/* <a href="/#" className="image">
            <img src={dane} alt="" data-position="25% 25%" />
          </a> */}
          <div className="content">
            <div className="inner">
              <h2>Wendy Herongrove</h2>
              <h3>Co-founder</h3>
              <p>
              Holds a PhD in Linguistics with a focus in natural language processing. She specializes in complex linguistic phenomena and adapting machine reading to new domains.
              </p>
              {/* <ul className="actions">
                <li>
                  <Link className="button" to="/generic">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
            {/* <ul className="icons">
              <li>
                <a href="https://scholar.google.com/citations?user=Rdp-tpgAAAAJ" className="fa-book">
                <span className="label">Google Scholar</span>
                </a>
              </li>
              <li>
                <a href="https://github.com/danebell" className="fa-github">
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/danebellnlp" className="fa-twitter">
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/danebell" className="fa-linkedin">
                  <span className="label">LinkedIn</span>
                </a>
              </li>
            </ul> */}
          </div>
        </section>
        <section>
          {/* <a href="/#" className="image">
            <img src={dane} alt="" data-position="25% 25%" />
          </a> */}
          <div className="content">
            <div className="inner">
              <h2>Keith Alcock</h2>
              {/* <h3>Lead SDE</h3> */}
              <p>
                Started programming professionally more than 40 years ago and has worked in the area of language technology for multiple research universities and startups.
              </p>
            </div>
            <ul className="icons">
              <li>
                <a href="http://www.keithalcock.com" className="fa-home">
                  <span className="label">Home</span>
                </a>
              </li>
              <li>
                <a href="https://scholar.google.com/citations?user=W21GjewAAAAJ" className="fa-book">
                <span className="label">Google Scholar</span>
                </a>
              </li>
              <li>
                <a href="https://github.com/kwalcock" className="fa-github">
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/keithalcock/" className="fa-linkedin">
                  <span className="label">LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </section>
      </section>

      <section id="what-we-do" className="wrapper style4 fade-up">
        <div className="inner">
          <h2>What we do</h2>
          <p>
          Our natural language processing (NLP) technology accelerates discovery by distilling unstructured text to reveal cause-and-effect relations and knowledge gaps that provide insight and impact on your development priorities.
          </p>
          <p>
            The company was founded in 2014 by a team of University of Arizona researchers with expertise in language-understanding systems. Lum's work was initially funded by DARPA through the Big Mechanism program to uncover novel genetic pathways for seven types of cancer. Subsequently, the Bill & Melinda Gates Foundation's used Lum AI in their Healthy Birth, Growth and Development (HBGD) initiative to successfully retrieve, assemble and interpret knowledge fragments from more than a hundred thousand publications relevant to children's health.
          </p>
          {/* <div className="features">
            <section>
              <span className="icon major fa-code" />
              <h3>Test</h3>
              <p>
                Blarg
              </p>
            </section>
            <section>
              <span className="icon major fa-lock" />
              <h3>Test 2</h3>
              <p>
                Blarg
              </p>
            </section>
            <section>
              <span className="icon major fa-cog" />
              <h3>Sed erat ullam corper</h3>
              <p>
                Phasellus convallis elit id ullam corper amet et pulvinar. Duis
                aliquam turpis mauris, sed ultricies erat dapibus.
              </p>
            </section>
            <section>
              <span className="icon major fa-desktop" />
              <h3>Veroeros quis lorem</h3>
              <p>
                Phasellus convallis elit id ullam corper amet et pulvinar. Duis
                aliquam turpis mauris, sed ultricies erat dapibus.
              </p>
            </section>
            <section>
              <span className="icon major fa-chain" />
              <h3>Urna quis bibendum</h3>
              <p>
                Phasellus convallis elit id ullam corper amet et pulvinar. Duis
                aliquam turpis mauris, sed ultricies erat dapibus.
              </p>
            </section>
            <section>
              <span className="icon major fa-diamond" />
              <h3>Aliquam urna dapibus</h3>
              <p>
                Phasellus convallis elit id ullam corper amet et pulvinar. Duis
                aliquam turpis mauris, sed ultricies erat dapibus.
              </p>
            </section>
          </div>
          <ul className="actions">
            <li>
              <Link className="button" to="/generic">
                Learn more
              </Link>
            </li>
          </ul> */}
      </div>
      </section>

      <section id="get-in-touch" className="wrapper style1 fade-up">
        <div className="inner">
          <h2>Get in touch</h2>
          <p>
            {/* Phasellus convallis elit id ullamcorper pulvinar. Duis aliquam
            turpis mauris, eu ultricies erat malesuada quis. Aliquam dapibus,
            lacus eget hendrerit bibendum, urna est aliquam sem, sit amet
            imperdiet est velit quis lorem. */}
          </p>
          <div className="split style1">
            {/* <section>
              <form method="post" action="#">
                <div className="fields">
                  <div className="field half">
                    <label for="name">Name</label>
                    <input type="text" name="name" id="name" />
                  </div>
                  <div className="field half">
                    <label for="email">Email</label>
                    <input type="text" name="email" id="email" />
                  </div>
                  <div className="field">
                    <label for="message">Message</label>
                    <textarea name="message" id="message" rows="5" />
                  </div>
                </div>
                <ul className="actions">
                  <li>
                    <a href="/#" className="button submit">
                      Send Message
                    </a>
                  </li>
                </ul>
              </form>
            </section> */}
            <section>
              <ul className="contact">
                {/* <li>
                  <h3>Address</h3>
                  <span>
                    12345 Somewhere Road #654
                    <br />
                    Nashville, TN 00000-0000
                    <br />
                    USA
                  </span>
                </li> */}
                <li>
                  <h3>Email</h3>
                  <a href="/#">hello@lum.ai</a>
                </li>
                {/* <li>
                  <h3>Phone</h3>
                  <span>(000) 000-0000</span>
                </li> */}
              </ul>
            </section>
            <section>
              <h3>Social</h3>
              <ul className="icons">
                {/* <li>
                  <a href="/#" className="fa-twitter">
                    <span className="label">Twitter</span>
                  </a>
                </li> */}
                <li>
                  <a href="https://github.com/lum-ai" className="fa-github">
                    <span className="label">GitHub</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/lum-ai" className="fa-linkedin">
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default IndexPage;
